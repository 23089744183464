import Send from '../../trans.client.js'

export default {
  // 중국 국가별 고객 ID 구분 정보
  getCatId (ctrCd) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/common/cat-id',
        method: 'get',
        params: {
          ctrCd: ctrCd
        }
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getHscdValidation (params) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/common/hscd-validation',
        method: 'get',
        params: params
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPolShaTsYn (hidCS008I) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/common/pol-sha-ts-yn',
        method: 'get',
        params: {
          hidCS008I: hidCS008I
        }
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCasNoPortCheck (hidCS008I) {
    return Send({
      method: 'get',
      url: '/trans/common/cas-no-port',
      params: { hidCS008I: hidCS008I }
    })
  },
  getIranCheck (params) {
    return Send({
      method: 'get',
      url: '/trans/common/iran-input',
      params: {
        kndCd: params.kndCd,
        cont: params.cont,
        hsCd: params.hsCd
      }
    })
  },
  saveHsCdIranMulti (regParams) {
    return Send({
      url: '/trans/common/save-iran-hs-cd',
      method: 'post',
      data: regParams
    })
  },
  getCtrInfo (params) {
    return Send({
      method: 'get',
      url: '/trans/common/ctr-info',
      params: {
        plcCd: params.plcCd
      }
    })
  },
  dgFPointCheck (imdunNo) {
    return Send({
      url: '/trans/common/dg-fls-tmpr',
      method: 'get',
      params: {
        imdunNo: imdunNo
      }
    })
  },
  findUserVocYn () {
    return Send({
      url: '/trans/common/user-voc-yn',
      method: 'get'
    })
  },
  getNboAgentCd (params) {
    return Send({
      method: 'get',
      url: '/trans/common/nbo-agent-cd',
      params: params
    })
  },
  getDgMaxCheck (params) {
    return Send({
      method: 'get',
      url: '/trans/common/dg-max',
      params: {
        cntrSzCd: params.cntrSzCd,
        cntrTypCd: params.cntrTypCd,
        flag: params.flag,
        imdunNo: params.imdunNo,
        imdgSeq: params.imdgSeq,
        dgMatlCd: params.dgMatlCd
      }
    })
  },
  getDgVolumnCheck (params) {
    return Send({
      method: 'get',
      url: '/trans/common/dg-volume',
      params: params
    })
  },
  findCntrInfo (cntrNo) {
    return Send({
      url: '/trans/common/cntr-no-info',
      method: 'get',
      params: {
        cntrNo: cntrNo
      }
    })
  },
  searchCtrCount (params) {
    return Send({
      url: '/trans/common/cntr-ctr-count',
      method: 'get',
      params: {
        cntrNo: params.cntrNo,
        bkgNo: params.bkgNo
      }
    })
  },
  findIranHsCd (bkgNo) {
    return Send({
      url: '/trans/common/find-iran-hs-cd',
      method: 'get',
      params: {
        bkgNo: bkgNo
      }
    })
  },
  isHaveAuth (params) {
    return Send({
      url: '/trans/common/is-have-auth',
      method: 'get',
      spinner: false,
      params: {
        bizCd: params.bizCd,
        rqRno: params.rqRno,
        eiCatCd: params.eiCatCd
      }
    })
  },
  getBkgDelegateYn (params) {
    return Send({
      url: '/trans/common/bkg-delegate-yn',
      method: 'get',
      params: {
        bkgNo: params.bkgNo,
        arrCstGrpCatCd: params.arrCstGrpCatCd
      },
      paramsSerializer: paramsObj => {
        const params = new URLSearchParams()
        for (const key in paramsObj) {
          if (Array.isArray(paramsObj[key]) && paramsObj[key].length === 0) {
            continue
          }
          params.append(key, paramsObj[key])
        }
        return params.toString()
      }
    })
  },
  getBlDelegateYn (params) {
    return Send({
      url: '/trans/common/bl-delegate-yn',
      method: 'get',
      params: {
        blNo: params.blNo,
        arrCstGrpCatCd: params.arrCstGrpCatCd
      },
      paramsSerializer: paramsObj => {
        const params = new URLSearchParams()
        for (const key in paramsObj) {
          if (Array.isArray(paramsObj[key]) && paramsObj[key].length === 0) {
            continue
          }
          params.append(key, paramsObj[key])
        }
        return params.toString()
      }
    })
  },
  //D/O발행 PDF 출력시 체크 관련 API
  checkAuthDoCp (params) {
    return Send({
      url: '/trans/common/check-auth-do-cp?blNoArr=' + params.blNoArr,
      method: 'get',
      params: {
        eiCatCd: params.eiCatCd
      }
    })
  },
  getBkgNo (blNo) {
    return Send({
      url: '/trans/common/search-bkg-no/' + blNo,
      method: 'get'
    })
  },
  // B/L 발행지역 목록 검색
  findBlIssuePlace (params) {
    return Send({
      url: '/trans/common/bl-issue-place',
      method: 'get',
      params: {
        ctrCd: params.ctrCd
      }
    })
  },
  // 터미널 선박 위치 조회하는 홈페이지
  findTrmlVesselLocation (params) {
      return Send({
        url: '/trans/common/trml-vessel-location',
        method: 'get',
        params: {
          trmlCd: params.trmlCd
        }
      })
  },
  // 컨테이너 VENT 종류 코드 검색
  checkCntrRfStsCodeYn (params) {
    return Send({
      url: '/trans/common/cntr-rfsts-code-yn',
      method: 'get',
      params: {
        keyword: params.keyword,
        kind: params.kind
      }
    })
  }
}

// trans.getCasNoPortCheck => transCommon.getCasNoPortCheck
// trans.getIranCheck => transCommon.getIranCheck
// trans.getDgMaxCheck => transCommon.getDgMaxCheck
